<template>
  <div>
    <v-card outlined class="containerbox" :min-height="415">
      <!-- Row for Table No and Export -->
      <v-row class="pa-2">
        <v-col cols="12" sm="6" md="6" lg="9" class="px-5 pt-10">
          <span color="primarytext">
            No.of Records: {{ totalRecords }}
          </span>
        </v-col>
        <v-col cols="12" sm="8" md="8" lg="3" class="px-1 ma-0 pt-6">
          <!-- Search add Comp -->
          <export-excel v-if="actionSetter('ExportSalesOrder')" class="btn btn-default" :fetch="exportExcel" :fields="json_fields" worksheet="Sheet1"
            :name="excelName" :before-generate="startDownload" :before-finish="finishDownload">
            <!-- Button to export -->
            <v-btn v-if="actionSetter('ExportSalesOrder')" :disabled="!totalRecords > 0" class="ma-2 btn-style float-right" elevation="1" outlined
              color="secondary" @click="fileName()">
              <v-icon dark left> mdi-file-export </v-icon>Export
            </v-btn>
          </export-excel>
        </v-col>
      </v-row>
      <!-- Table Row -->
      <v-row class="pa-0">
        <v-col cols="12" sm="12" md="12" lg="12" class="px-5 pt-0">
          <v-data-table :headers="headers" hide-default-footer :items="searchList" item-key="so_id">
            <template v-slot:item.sono="{ item }">
              <span v-if="!actionSetter('SalesOrderDetails') && !actionSetter('EditSalesOrder')">{{ item.sono }}</span>
              <span v-else-if="actionSetter('SalesOrderDetails') || actionSetter('EditSalesOrder')">

                <a v-bind:class="($vuetify.theme.dark) ? 'anchorDark' : 'anchorlight'" @click="soEdit(item)">
                  {{ item.sono }}
                </a>
              </span>
            </template>
            <template v-slot:item.active="{ item }">
              <span class="active" v-if="item.active == true">Active</span>
              <span class="inactive" v-else>Inactive</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon title="View Details" small class="mr-2" @click="soEdit(item)"
                :disabled="!actionSetter('SalesOrderDetails') && !actionSetter('EditSalesOrder')">
                mdi-eye</v-icon></template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- Pagnation -->
      <v-row>
        <v-col cols=" 12" lg="4" md="4" sm="4" class="pt-0 px-5"></v-col>
        <v-col cols="12" lg="5" md="5" sm="5" class="pt-0 px-5">
          <v-pagination v-if="totalRecords > 0" v-model="page" @input="searchPageData(userId, searchString, page, AdvJson, filterType)"
            :length="pageCount" :total-visible="10">
          </v-pagination>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="3" class="pt-2 px-5"> </v-col>
      </v-row>
    </v-card>
    <exportLimit v-if="exportDialog" :exportDialog="exportDialog" :totalRecords="totalRecords" @exportData="exportData()" />



  </div>
</template>
<script>
import salesService from './js/sales-order-service';
import EncryptUtility from '../utility/js/encrypt-utility';
import AddFavourite from "../utility/js/addFav"
import { bus } from '../../main';
//import moment from 'moment'
import Utility from '../../shared/utility.js';
import exportLimit from '../common/export-limit.vue';

export default {
  name: 'Address',
  props: {
    SearchStr: String,
    searchData: String,
    detailSearch: String,
    searchObj: Object,
  },
  data() {
    return {
      excelName: "",
      headers: [{ text: 'Order Number', align: 'start', value: 'sono', class: 'primary customwhite--text', },
      { text: 'Order Type', value: 'order_type', class: 'primary customwhite--text', },
      { text: 'Project', value: 'project', class: 'primary customwhite--text', },
      { text: 'Outbound Status', value: 'outbound_status', class: 'primary customwhite--text' },
      { text: 'Inbound Status', value: 'inbound_status', class: 'primary customwhite--text' },
      { text: 'Created By', value: 'created_by', class: 'primary customwhite--text', },
      { text: 'Created Date', value: 'order_date', class: 'primary customwhite--text', },
      {
        text: 'Actions',
        value: 'actions',
        class: 'primary customwhite--text',
        sortable: false,
      },
      ],
      exportDialog: false,
      exportFlag: true,
      searchList: [],
      pageNumber: 1,
      pageCount: 1,
      page: 1,
      filterType: 0,
      totalRecords: 0,
      searchString: "",
      AdvJson: "",
      export50kData: false,
      userId: EncryptUtility.localStorageDecrypt('userID'),
      json_fields: {
        Order_Number: 'sono',
        Order_Type: 'order_type',
        Project: 'project',
        Outbound_Status: 'outbound_status',
        Inbound_Status: 'inbound_status',
        Created_By: 'created_by',
        Created_Date: 'order_date'
      },
      actionList: [],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },
  async created() {

    this.route = this.$route.path.replace('/', '');
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    // Search API called by Props
    if (EncryptUtility.localStorageDecrypt('SOSearch')) {
      this.searchObj = JSON.parse(EncryptUtility.localStorageDecrypt('SOSearch'))
      this.searchPageData(this.searchObj.UserId, this.searchObj.SearchStr, this.searchObj.PageNumber, this.searchObj.AdvJson, this.searchObj.FilterType);
      this.AdvJson = this.searchObj.AdvJson
      this.filterType = this.searchObj.FilterType
      this.searchString = this.searchObj.SearchStr;


    }
    //Bus Created so  that updated element can be created

    bus.$on('search', (data) => {
      this.searchPageData(data.UserId, data.SearchStr, data.PageNumber, data.AdvJson, data.FilterType);
      this.AdvJson = data.AdvJson
      this.filterType = data.FilterType
      this.searchString = data.SearchStr;
    })


    bus.$on('clearData', (data) => {
      if (data) {
        this.searchList = []
        this.pageCount = 0
        this.totalRecords = 0
        EncryptUtility.localStorageEncrypt("SOSearch", "");
      }
    })


  },

  destroyed() {
    bus.$off('search');
  },
  mounted() {
  },

  methods: {
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    // Export API
    async exportExcel() {
      this.searchString = this.searchObj.SearchStr;
      if (this.totalRecords <= 15000) {
        let searchObject = {
          SearchStr: this.searchString,
          AdvJson: this.AdvJson,
          PageNumber: 1,
          userId: parseInt(this.userId),
          SearchType: 2,
          FilterType: this.filterType
        };
        const date = new Date()
        this.excelName =
          'salesOrderList_' +
          (1 + date.getMonth()).toString().padStart(2, '0') + "_" +  // 9  
          date.getDate().toString().padStart(2, '0') + "_" +    // 30
          date.getFullYear() +
          '.xls';
        const response = await this.axios.post('/so/so_search', searchObject);
        let responseData = JSON.parse(response.data.body.message).Resultset;
        this.export50kData = false
        return responseData;
      }
      else {
        this.exportDialog = true
        this.finishDownload()
        return true
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
    },
    // end the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: 'Please Wait...',
      };
      this.$store.commit('ConfigModule/LoaderDialog', LoaderDialog);
    },
    //File Name for Export
    fileName() {
      if (this.totalRecords <= 15000 || this.export50kData)
        this.exportFlag = true

      this.excelName =
        'sales_order_list_' +
        new Date()
          .toISOString()
          .replace('T', '_')
          .replace('Z', '')
          .replace('.', '')
          .replaceAll('-', '_') +
        '.xls';
    },

    // To get the data of Dropdowns
    async searchPageData(userId, SearchStr, PageNumber, AdvJson, FilterType) {
      this.exportDialog = false
      this.searchList = []
      this.totalRecords = 0
      this.pageCount = 1
      let obj = {
        UserId: userId,
        SearchStr: SearchStr,
        PageNumber: PageNumber,
        AdvJson: AdvJson,
        FilterType: FilterType
      }

      let searchData = await salesService.postSearchData("post", obj)

      if (searchData.TotalRecords > 0) {
        this.searchList = searchData.Resultset.map((x) => ({
          sono: x.sono,
          order_type: x.order_type,
          project: x.project,
          order_date: Utility.convertESTToLocal(x.order_date),
          created_by: x.created_by,
          outbound_status: x.outbound_status,
          inbound_status: x.inbound_status,
          so_id: x.so_id,
        }))

        // localStorage.removeItem("SOSearch");
        EncryptUtility.localStorageEncrypt('SOSearch', JSON.stringify(obj))

        this.page = obj.PageNumber
        this.pageCount = searchData.TotalPages
        this.totalRecords = searchData.TotalRecords
        this.$vuetify.goTo(0);

      }
      else {
        this.searchList = []
        this.pageCount = 0
        this.totalRecords = 0
        Utility.showNoDataToast("No Data Available")
        EncryptUtility.localStorageEncrypt("SOSearch", "");
      }
    },
    //get Page level Action 
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== '') {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action,
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },

    //View details on click of eye icon in data table
    soEdit(item) {
      this.$router.push({
        name: 'salesOrderDetails',
        params: {
          id: btoa(item.so_id.toString())
        },
      });
    },
  },
  components: {
    exportLimit,
  },
}
</script>

<style scoped>
.anchorDark {
  text-decoration: underline !important;
  color: white !important;
}

.anchorlight {
  text-decoration: underline !important;
  color: var(--v-primarytext-base) !important;

}
</style>